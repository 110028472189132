import React from 'react';
import Case from '../components/case'


const CaseDevassa = (props) => (
    <Case 
        seoTitle="Forle Marketing Case Devassa"
        images={["1.jpg", "2.jpg", "3.jpg", "4.jpg"]} 
    >  
          <h1>Case Devassa</h1>
          <div className="row justify-content-center my-5">
            <div className="col-sm-12 col-lg-6">
                    <p>A <strong>Devassa Puro Malte Tropical</strong> tinha como objetivo <strong>ampliar seu relacionamento com consumidores</strong> em pontos de venda, na regi&atilde;o nordeste durante o <strong>Carnaval</strong>. Visando um forte relacionamento com o <strong>p&uacute;blico jovem</strong> que sabe o que &eacute; ser brasileiro e aproveitar o Carnaval da melhor forma. Pensando no jeito brasileiro de ser, o desafio era levar para o Carnaval todo o <strong>sabor e tropicalidade</strong>, que somente a <strong>Devassa Puro Malte</strong> pode oferecer. </p>
            </div>
            <div className="col-sm-12 col-lg-6">
                    <p>A Forle trouxe a <strong>transforma&ccedil;&atilde;o </strong>como premissa principal para <strong>o que &eacute; ser tropical</strong>, incentivando o consumidor a continuar <strong>criando recorda&ccedil;&otilde;es</strong> e formas de ser tropical. A <strong>ambienta&ccedil;&atilde;o</strong> em lojas da Devassa, trouxe n&atilde;o s&oacute; a exposi&ccedil;&atilde;o das latas, como tamb&eacute;m um <strong>QR Code interativo</strong>, em que o cliente poderia ver quando e onde os blocos patrocinados iriam tocar. </p>
            </div>
        </div>
    </Case>
)

export default CaseDevassa